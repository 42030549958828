import { CloseAccountCauseV3 } from '@/globalTypes';

export const closeAccountCauseMap: Record<CloseAccountCauseV3, string> = {
  AbusiveBehaviour: 'Abusive Behaviour',
  AdverseMedia: 'Adverse Media',
  ConfirmedFraud: 'Confirmed Fraud',
  ConfirmedPromotionAbuse: 'Confirmed Promotion Abuse',
  ExceededSOWF: 'Exceeded SOWF',
  FailureProvideDD: 'Failure Provide DD',
  MLRO: 'MLRO',
  MultipleAccounts: 'Multiple Accounts',
  NonCAPExtremeCRARisk: 'Non CAP Extreme CRA Risk',
  PEPSanctionsListMatch: 'PEP Sanctions List Match',
  PermanentCustomerRequest: 'Permanent Customer Request',
  ProhibitedCountry: 'Prohibited Country',
  ResponsibleGaming: 'Responsible Gaming',
  SanctionsListMatch: 'Sanctions List Match',
  SuspectedFraud: 'Suspect Fraud',
  SuspectedPromotionAbuse: 'Suspected Promotion Abuse',
  TemporaryCustomerRequest: 'Temporary Customer Request',
};
